











































import { Component, Ref, Vue } from 'vue-property-decorator';
import VueUeditorWrap from 'vue-ueditor-wrap';
import UploadFile from '@c/upload/UploadFile.vue';
import { CmsArticleAdd } from '@/http/api/ArticleApi';
import { IbreadcrumbItem } from '@/assets/model/interface';
import Breadcrum from '@c/breadcrum/Breadcrum.vue';


interface Iarticle {
  title?: string | any[];
  source?: string | any[];
  abstract?: string | any[];
  content?: any | any[];
  attFiles?: any | any[];
}
@Component({
  components: {
    VueUeditorWrap,
    UploadFile,
    Breadcrum,
  },
})
export default class ArticleContribute extends Vue {
  @Ref() private articleFrom!: HTMLDListElement;
  private breadcrumbList: IbreadcrumbItem[] = [
    { name: '会员服务', path: '/serve' },
    { name: '会员简介', path: '/serve/memberintroduction' },
    { name: '我要投稿', path: '' },
  ];
  private loading: boolean = false;
  private pageLoading: boolean = false;
  private articleClass: any = 'consult';
  private articleInfo: Iarticle = {
    title: '',
    source: '',
    abstract: '',
    content: '',
    attFiles: '',
  };
  private articleRules: Iarticle = {
    title: [
      { required: true, message: '请输入标题', trigger: 'blur' },
    ],
  };
  private uploadFileConfig: any = {
    limit: 1,
    callback: [],
  };
  private ueditorConfig: any = {
    UEDITOR_HOME_URL: '/web/ueditor/',
    initialFrameHeight: 400,
  };
  private editorInstance: any = '';
  private article: any = {
    content: '',
  };
  private created() {
    this.articleClass = this.$route.query.article;
  }
  // 实例化编辑器
  private ready(editorInstance: any) {
    this.editorInstance = editorInstance;
  }
  private UploadFileFun(val: any) {
    this.articleInfo.attFiles = val;
  }
  private goto() :void {
    // this.$router.push('serve/membermien/record')
     this.$router.go(-1);
  }

  // 保存+发布+修改
  private articleSubmit() {
    this.loading = true;
    (this.articleFrom as any).validate((resBool: boolean) => {
      if (resBool) {
        CmsArticleAdd({
          attFiles: this.articleInfo.attFiles,
          content: this.articleInfo.content,
          contentPure: this.articleInfo.abstract,
          source: this.articleInfo.source,
          title: this.articleInfo.title,
        }).then((res) => {
          if (res.code === '000') {
            this.$message.success('提交成功');
            this.$router.go(-1);
          }
        });
      } else {
        this.loading = false;
      }
    });
  }
}
